<template>
  <div class="nao-pagos mt-4">
    <div class="container-fluid pb-5">
      <div class="row text-center">
        <div class="col-12 px-0 px-md-4">
          <div class="title-container mt-5 mb-2">
            <div class="title-with-lines h1">
              NAO PAGOS
            </div>
          </div>
          
          <div class="col-10 offset-1">
            <div class="my-5 h3 texto-podras">
            Es una plataforma segura de pagos donde podrás
            </div>
            
            <ul class="custom-list">
              <li>Reportar los pagos de tus reservaciones</li>
              <li>Reportar las facturas de comisiones, así como complementos de pago</li>
              <li>Revisar el status de los pagos reportados, incluido el pago de tu comisión</li>
            </ul>  
          </div>

          
          <div class="text-center">
            <a class="btn btn-secondary btn-accion my-5" href="https://naopagos.com/" target="_blank">Ingresa aquí</a>
          </div>
        
          <p class="col-10 offset-1 text-center">
            Para poder ingresar a la plataforma, deberás contar con un usuario y contraseña, 
            en caso de no contar con ello, por favor contacta a tu BDM
          </p>
            
          <div class="my-5 h3 mira-video px-2 px-md-0 ">
            MIRA EL VIDEO TUTORIAL DE NAO PAGOS
          </div>
          
          <img src="../assets/NAO/pagos/play-nao-pagos.png" class="w-100 clickable" @click="showVideo" v-if="!videoVisible">
          <video class="w-100" src="https://naotravelco.com/img/Curso de entrenamiento NAO Pagos V3.mp4" controls controlsList="nodownload" v-if="videoVisible" ref="tutorialVideo"></video>
            
          <div class="my-5 h3 contenido">
            CONTENIDO DEL VIDEO
          </div>
          
          <div class="row">
            <div class="col text-left list-contenido px-4 px-md-0">
              <div> <b>00:01</b> - Introducción </div>
              <div> <b>00:52</b> - Secciones página principal </div>
              <div> <b>03:06</b> - Cómo ingresar un pago </div>
              <div> <b>15:40</b> - Cómo reportar una factura de comisión </div>
              <div> <b>18:33</b> - Seguimiento a status de transacciones </div>
              <div> <b>23:50</b> - Cómo ingresar un complemento de pago </div>
              <div> <b>26:44</b> - Cómo volver a tramitar pagos rechazados</div>
              
              <div class="text-center">
                <a class="btn btn-secondary btn-accion my-5" href="../assets/NAO/pagos/Manual de Usuario SGP.pdf" target="_blank">VER MANUAL DE USUARIO</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoVisible: false
    };
  },
  methods: {
    showVideo() {
      this.videoVisible = true;
      this.$nextTick(() => {
        this.$refs.tutorialVideo.play();
      });
    },
    goto(url) {
      if (url.includes('http')) {
        console.log('web', url)
        window.open(url)
      } else {
        window.location.href = url
        console.log('in', url)
      }
    }
  }
};
</script>

<style scoped lang="scss">

.h1{
  font-size: 2em;
  font-weight: 700;
}
.title-container{
  position: relative;
}

.title-container::before,
.title-container::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 100%;
  height: 6px;
  background-color: #585B5D;
  z-index: -1;
}

.title-container::before {
  left: 0;
}

.title-container::after {
  right: 0;
}

.title-with-lines {
  display: inline-block;
  background-color: white;
  padding: 0 15px;
  z-index: 1;
  position: relative;
}


.texto-podras{
  font-weight: 700;
  font-size: 1.3em;;
}

* {
  font-size: 1.3rem;
  color: #585B5D; 
}

a.btn-accion {
  font-size: 24px;
  font-weight: 600;
  font-family: sans-serif !important;
  color: white !important;
  text-transform: uppercase;
  padding: 0.2rem 2rem;
  background-color: #585B5D;
  border-color: #585B5D;
}

img.clickable {
  cursor: pointer;
}

.custom-list {
  margin-left: auto;
  margin-right: auto;
  max-width: 80%;
  list-style: disc inside;
  padding-left: 0;

  li {
    text-align: left;
    margin-bottom: 0.5rem; /* Ajustar la separación entre los elementos si es necesario */
  }
}

.mira-video{
  font-size: 1.2em;
  font-weight: 700;
}

.contenido{
  font-weight: 700;
}

.list-contenido{
  max-width: 24em;
  margin-left: auto;
  margin-right: auto;
}

ol {
  padding-left: 4rem;

  & > li::marker {
    font-size: 3rem;
    font-weight: bold;
    color: #b9b9b9;
    text-align: left;
  }

  & > li {
    text-align: justify;
  }
}
</style>
